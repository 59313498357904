<script>
  import {
    required,
    helpers
  } from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";
  import { loginDBNextStep, getAgencies} from "@/api/auth";
  import appConfig from "../../../app.config";
  import Multiselect from "@vueform/multiselect";
  import "@vueform/multiselect/themes/default.css";
  import myLoader from "../../components/myLoader.vue";


  export default {
    setup() {
     return { v$: useVuelidate() };
    },
    page: {
      title: "Login",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },
    data() {
      return {
        userId: "",
        password: "",
        company:"", 
        companies:[],
        agency:null,
        agencies:[],
        submitted: false,
        authError: false,
        messageError:"",
        tryingToLogIn: false,
        isAuthError: false,
        userPicture:null,
        isLoader:false,
        proyectId:null,
      };
    },
    validations: {
      agency: {
        required: helpers.withMessage("La agencia es requerida", required),
      },
      company: {
        required: helpers.withMessage("La Empresa es requerida", required),
      },
    },
    computed: {
    },
    components: {
      Multiselect,
      myLoader,

    },
    mounted() {
      this.get_dataCompanies();
    },
    methods: {
      async get_dataAgencies(companyId) {
        this.isLoader=true
        var data= await JSON.parse(localStorage.dataLogin)
        this.userId=data.username
        this.projectId=data.project
        const result = await getAgencies({
          'company' : companyId, 'userid' :this.userId, 'proyect' :this.projectId,
        })
        this.agencies = [];
        result.result.data.map((item) => {
          this.agencies.push({
            value: item.agencyId,
            name: item.nameAgency
          });
        });
        this.agency=this.agencies[0].value
        this.isLoader=false;
      },  
      async get_dataCompanies() {
        var  data= await JSON.parse(localStorage.dataLogin)

        if (data.userPicture ) {
          this.userPicture = data.userPicture;
        }
        if (data.listDb ) {

          this.companies = [];
          data.listDb.map((item) => {
            this.companies.push({
              value: item.CMPYDBNAME,
              name: item.CMPYNAME
            });
          });
        }
         
      },  
     
      tryToLogInDB() {
        this.submitted = true;
        // stop here if form is invalid
        this.v$.$touch();
        if (this.v$.$invalid) {
          return;
        }else{
          this.signinapi();
        }
      },
      async signinapi() {
        this.isLoader=true;
        const result = await loginDBNextStep({
          'username' : this.userId, 'proyect' :this.projectId,
          'idname':this.company, 'agency':this.agency,
        })
        this.messageError='';
        if (result.state == false) {
          this.isLoader=false; 
          this.authError = true;
          if(typeof result.info.message==='object'){
            result.info.message.map((item) => {
              this.messageError=this.messageError+item+' \n';
            });
          }else{   
            this.messageError=result.info.message;
          }
          return ;
        }else{
          this.authError = false;
          // inicia aplicación
          this.$router.push({
            path: '/'
          });
        }
      }
      
    },
    
    
  }
  
</script>

<template>

  <div class="auth-page-wrapper pt-5" >
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-none"></div>

      <div class="shape">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8
            1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content"  >
      <div class="container" >
        <div class="row">
          <div class="col-lg-12">
           
          </div>
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
          <div class="col-md-14 col-lg-7 col-xl-6">
            <div class="card mt-5">
              <div class="card-body p-10">
                <div class="text-center mt-2">
                  <div class="text-center mt-sm-1 mb-4 text-white-40">
                    <div>
                      <img src="@/assets/images/NSLogoLight.png" alt="" height="60" />
                    </div>
                    <p class="mt-3 fs-25 fw-medium">
                      Tecnología aplicada al transporte y la lógistica  DB
                    </p>
                  </div>
                </div>
                <div class="p-2 mt-4">
                  <b-alert v-model="authError" variant="danger" class="mt-3" dismissible>{{ messageError }}</b-alert>

                  <form @submit.prevent="tryToLogInDB">
                    <div class="row">
                      <div class="col-8">
                        <!-- <div class="mb-3">
                      
                          <label class="form-label">Grupo</label>
                          <input
                            type="text"
                            v-model="user"
                            class="form-control"
                            id="user"
                            :class= "[(submitted && Object.keys( v$.user.$errors).length === 0) ? 'is-valid': 
                            (submitted && Object.keys( v$.user.$errors).length != 0) ? 'is-invalid':'form-control' ] "
                            placeholder="usuario"
                          />
                          <div
                            v-for="(item, index) in v$.user.$errors"
                            :key="index"
                            class="invalid-feedback"
                          >
                            <span v-if="item.$message">{{ item.$message }}</span>
                          </div>
                        </div> -->
                        
                        <div class="mb-6">
                          <label class="form-label">Empresa</label>
                          <Multiselect :close-on-select="true" :searchable="true" :create-option="true" 
                           :options="companies"
                           label="name"
                           :value="companies.value" 
                            :class= "[(submitted && Object.keys( v$.company.$errors).length === 0) ? 'form-Multiselect form-Multiselect-lg is-valid': 
                            (submitted && Object.keys( v$.company.$errors).length != 0) ? 'form-Multiselect form-Multiselect-lg is-invalid':'form-Multiselect form-Multiselect-lg' ] "
                            aria-label=".form-select"
                            v-model="company" id="company"
                            @select="get_dataAgencies"
                          >
                          
                          </Multiselect>
                          <div
                            v-for="(item, index) in v$.company.$errors"
                            :key="index"
                            class="text-danger" style="font-size: 13px"
                          >
                            <span v-if="item.$message">{{ item.$message }}</span>
                          </div>
                        </div>
                        
                        <div class="mt-3 mb-6">
                          <label class="form-label">Agencia</label>
                          <select
                            :class= "[(submitted && Object.keys( v$.agency.$errors).length === 0) ? 'form-select form-select-md is-valid': 
                            (submitted && Object.keys( v$.agency.$errors).length != 0) ? 'form-select form-select-md is-invalid':'form-select form-select-md' ] "
                            aria-label=".form-select"
                            v-model="agency" id="agency"
                          >
                            <option v-for="item in agencies" 
                            :key="item.value" :value="item.value"
                            class="h-100"
                            >{{item.name}}
                            </option>
                          </select>
                          <div
                            v-for="(item, index) in v$.agency.$errors"
                            :key="index"
                            class="text-danger" style="font-size: 13px"
                          >
                            <span v-if="item.$message">{{ item.$message }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="mt-4" >
                          <img :src="userPicture"
                          class="border border-success border-groove avatar-xl 
                          rounded-3
                          bg-light mx-auto mb-1 responsive" /> 
                        </div> 
                      </div>
                    </div>
                   
                    <div class="mt-4">
                      <button class="btn btn-success w-100" type="submit" >
                        Ingresar
                      </button>
                    </div>

                  </form>
                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <div v-if="isLoader" style="position:absolute; top: 35%; 
        left:40%;
        z-index: 9999;  "  >
          <myLoader /> 
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                &copy; {{ new Date().getFullYear() }} NEXT STEP COLOMBIA 
                <i class="mdi mdi-heart text-danger"></i> Siempre Adelante
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->

</template>




