import axios from "axios";
import { authHeader, validateWebToken } from "../helpers/authservice/auth-header";

/*
 * Enpoints para la parte de autenticacion
 */

//Url de api
// console.log("ENTORNO");
// console.log(process.env.VUE_APP_ENV === "DEV");
let urlApi =
  process.env.VUE_APP_ENV === "DEV"
    ? process.env.VUE_APP_API_URL
    : process.env.VUE_APP_API_URL_PRODUCTION;


//Funcion infrastructure
const get_projects = async () => {
  try {
    const result = await axios.get(`${urlApi}/apideliveryns/cloud/v1/auth/get_proyecto`);
    return { state: true, result:result.data}
  }catch (error) {
    if (error.response) {
      return { state: false, message: error.response.data.data }; // => the response payload
    }
  }
};

//Funcion para loguearse
const login = async (body) => {
  try {
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/auth/login`, body);
    result.data.data.project=body.proyect
    let data= JSON.stringify(result.data.data)
    localStorage.setItem("dataLogin",  data);
    return { state: true, result:result };
  } catch (error) {
    if (error.response) {
      validateWebToken(error.response);
      return { state: false, info: error.response.data}; // => the response payload
    }
  }
};

//Funcion agencias
const getAgencies = async (body) => {
  try {
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/auth/getAgency`, body);
    return { state: true, result:result.data}
  }catch (error) {
    if (error.response) {
      return { state: false, message: error.response.data.data }; // => the response payload
    }
  }
};

//Funcion para loguearse
const loginDB = async (body) => {
  try {     
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/auth/loginDb`, body);
    let data=result.data.data
    let dataToken={'access_token':data.access_token, 
                  'expires_in':data.expires_in,
                  'token_type':data.token_type,}
    localStorage.setItem("dataToken",  JSON.stringify(dataToken));
    localStorage.setItem("dataMenu",  JSON.stringify(data.user.menu));
    let dataUser=data.user 
    delete dataUser.menu
    localStorage.setItem("dataUser",  JSON.stringify(dataUser));
    // console.log("loginDB-data",result.data.data );
    return { state: true, result:result.data };
  } catch (error) {
    if (error.response) {
      return { state: false, info: error.response}; // => the response payload
    }
  }
};

//Funcion para loguearse NS
const loginDBNextStep = async (body) => {
  try {     
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/auth/loginDbNextStep`, body);
    let data=result.data.data
    let dataToken={'access_token':data.access_token, 
                  'expires_in':data.expires_in,
                  'token_type':data.token_type,}
    localStorage.setItem("dataToken",  JSON.stringify(dataToken));
    localStorage.setItem("dataMenu",  JSON.stringify(data.user.menu));
    let dataUser=data.user 
    delete dataUser.menu
    localStorage.setItem("dataUser",  JSON.stringify(dataUser));
    // console.log("loginDB-data",result.data.data );
    return { state: true, result:result.data };
  } catch (error) {
    if (error.response) {
      return { state: false, info: error.response}; // => the response payload
    }
  }
};
//Funcion para obtener el usuario actual y ver si el token es valido aun.
const me = async () => {
  try {
    const result = await axios.get(`${urlApi}api/auth/me`, {
      headers: authHeader(),
    });
    localStorage.setItem("userdata", JSON.stringify(result.data.data));
    localStorage.setItem("userid", result.data.data.id);
    return { state: true };
  } catch (error) {
    if (error.response) {
      localStorage.clear(); // Limpiar localstoreage al detectar token vencido
      return { state: false, message: error.response.data.message }; // => the response payload
    }
  }
};

//Funcion para hacer logout
const logout = async () => {
  try {

    const result = await axios.get(
      `${urlApi}/apideliveryns/cloud/v1/auth/logout`,
      {
        headers: authHeader(),
      }
    );
    localStorage.clear();
    return { state: true, result: result };
  } catch (error) {
    if (error.response) {
      validateWebToken(error.response);
      return { state: false, message: error.response.data.message }; // => the response payload
    }
  }
};

//Funcion para checkear el acceso a un modulo
const checkRoutePermissions = async (route_name) => {
  const result = await axios.post(
    `${urlApi}api/security/check/route`,
    {
      route_name: route_name,
    },
    {
      headers: authHeader(),
    }
  );
  if (result.code == 200) {
    return result.data.has_permission;
  } else {
    if (result.code == 401) {
      return result.data;
    } else {
      return false;
    }
  }
};

//Funcion para doble autenticacion
const G2FA = async (verifyCode) => {
  try {
    const result = await axios.post(
      `${urlApi}api/2fa`,
      {
        "verify-code": verifyCode,
      },
      {
        headers: authHeader(),
      }
    );
    return { state: true, result: result };
  } catch (error) {
    if (error.response) {
      return { state: false, message: error.response.data.message }; // => the response payload
    }
  }
};

//Funcion para loguearse
const G2FACheck = async (current) => {
  try {
    const result = await axios.post(
      `${urlApi}api/2fa/just/check`,
      {
        current: current,
      },
      {
        headers: authHeader(),
      }
    );
    return { state: true, result: result };
  } catch (error) {
    if (error.response) {
      return { state: false, message: error.response.data.message }; // => the response payload
    }
  }
};

const sendCodeRecovery = async (email) => {
  try {
    const result = await axios.post(
      `${urlApi}api/auth/recovery/pass`,
      {
        item: email,
      },
      {
        headers: authHeader(),
      }
    );
    return { state: true, result: result };
  } catch (error) {
    if (error.response) {
      return { state: false, message: error?.response?.data?.data?.messages[0] || "Error inesperado" }; // => the response payload
    }
  }
};

const checkCodeRecovery = async (item, code) => {
  try {
    const result = await axios.post(
      `${urlApi}api/auth/validate/token`,
      {
        item: item,
        token: code,
      },
      {
        headers: authHeader(),
      }
    );
    return { state: true, result: result };
  } catch (error) {
    if (error.response) {
      return { state: false, message: error?.response?.data?.data?.messages[0] || "Error inesperado" }; // => the response payload
    }
  }
};


export { login, me, logout, checkRoutePermissions,getAgencies,loginDB,loginDBNextStep,
  G2FA, G2FACheck, sendCodeRecovery, checkCodeRecovery, get_projects };
